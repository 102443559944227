import wedding_card from "../../assets/wedding-card.png";
import GoogleMap from "../../components/Map";
import "./styles.scss";
import Audio from "../../components/Audio";
import FormCard from "../../components/FormCard";
import Footer from "../../components/Footer";
import background_music from "../../assets/background-music.mp3";

function Card() {
  return (
    <div className="card">
      <div className="container-lg">
        <div className="card-animation">
          <div className="card-container">
            <Audio src={background_music} />
            <img
              className="card-img"
              src={wedding_card}
              alt="Invitation mariage"
            />
            <GoogleMap query="Mairie du 19e, 7 place Armand Carrel, 75019 Paris" />
            <FormCard types={{ mairie: "Mairie" }} type="Mairie" />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
