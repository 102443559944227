import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faMusic } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Audio = ({ src }) => {
  const audioRef = useRef();
  const [playing, setPlaying] = useState(false);

  const pausePlay = () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  return (
    <div className="music-bar">
      <audio
        ref={audioRef}
        className="music"
        autoPlay
        loop
        src={src}
        controlsList="nodownload noplaybackrate"
        onPlaying={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
      />

      <div className="music-controls">
        <FontAwesomeIcon icon={faMusic} />

        {playing ? (
          <FontAwesomeIcon icon={faPause} onClick={pausePlay} />
        ) : (
          <FontAwesomeIcon icon={faPlay} onClick={pausePlay} />
        )}
      </div>
    </div>
  );
};

export default Audio;
