import { useState, useRef, useEffect } from "react";
import Input from "../Input";
import api from "../../api/api";
import { toast } from "react-toastify";
import "./styles.scss";
import Button from "../Button";
import SwitchSelector from "react-switch-selector";

// types: mairie, reception, henne, shabbat
const FormCard = ({ types, type }) => {
  const formRef = useRef();

  const [name, setName] = useState("");
  const [word, setWord] = useState("");
  const [hasPosted, setPosted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [counts, setCounts] = useState(
    Object.fromEntries(Object.keys(types).map((x) => [x, undefined]))
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSent = localStorage.getItem("is-sent");
    if (isSent) {
      setPosted(true);
    }
  }, []);

  const options = [
    { label: "Assisteront", value: 0 },
    { label: "N'assisteront pas", value: 1 },
  ];

  const onChange = (newValue) => {
    setCurrentIndex(newValue);
  };

  const setCount = (countName, value) => {
    setCounts((previousState) => {
      const newState = { ...previousState };
      newState[countName] = value;
      return newState;
    });
  };

  const sendRSVP = () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }
    if (!name) {
      toast("Votre nom est obligatoire pour envoyer votre réponse !");

      return;
    }

    let request = { name: name, type: type, word: word };
    if (currentIndex === 0) {
      for (const item in counts) {
        const currentCount = counts[item];
        if (currentCount && currentCount > 0) {
          if (currentCount > 9) {
            toast(
              "Le nombre de personnes venant à cet évènement ne peut dépasser 9 personnes."
            );
            return;
          }

          request[`${item}Count`] = currentCount;
        }
      }
    }

    setLoading(true);
    api
      .postRSVP(request)
      .then((response) => {
        console.log(response);
        setPosted(true);
        localStorage.setItem("is-sent", true);
      })
      .catch((err) => {
        toast("L'envoi de la réponse a échoué, ressayez plus tard.");
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendButtonDisabled = () => {
    return name.length === 0;
  };

  return (
    <div className="form-card">
      <h2 className="form-card-title">RSVP</h2>
      {hasPosted ? (
        <p>Merci de votre réponse !</p>
      ) : (
        <div>
          <form ref={formRef}>
            <Input
              label="Nom(s) &amp; Prénom(s)"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              required
            />

            <SwitchSelector
              onChange={onChange}
              options={options}
              initialSelectedIndex={currentIndex}
              selectedBackgroundColor={"#168cb1"}
              selectedFontColor={"#fff"}
              fontColor={"#02141b"}
              wrapperBorderRadius={8}
              optionBorderRadius={8}
              border={"none"}
            />
            {currentIndex === 0
              ? Object.keys(types).map((type) => {
                  return (
                    <Input
                      label={types[type]}
                      type="number"
                      value={counts[type]}
                      onChange={(e) => setCount(type, e.target.value)}
                      min={0}
                      max={9}
                      id={type}
                    />
                  );
                })
              : null}
            <Input
              label="Un petit mot ?"
              value={word}
              onChange={(e) => setWord(e.target.value)}
              id="word"
            />
          </form>
          <div className="button-container">
            <Button
              onClick={sendRSVP}
              disabled={sendButtonDisabled()}
              loading={loading}
            >
              <p>Envoyer</p>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormCard;
