import React from "react";

export const GoogleMap = (props) => {
  return (
    <iframe
      title={`Carte vers ${props.query}`}
      width="100%"
      height="450"
      style={{ border: 0 }}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCXEpL_2O3FGbW2v62ldR1bebUwElFUuQk&q=${props.query}`}
    ></iframe>
  );
};

export default GoogleMap;
