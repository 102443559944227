import "./styles.scss";

const Input = ({
  label,
  placeholder,
  type,
  value,
  required,
  onChange,
  min,
  max,
  id,
}) => {
  return (
    <div className="input">
      {label ? (
        <label className="input-label" htmlFor={id}>
          {label}
          {required ? <span>*</span> : null}
        </label>
      ) : null}
      <input
        className="input-input"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        id={id}
      />
    </div>
  );
};

export default Input;
